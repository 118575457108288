.custom-sidebar{
    min-height: 500px;
    height: calc(100vh - 64px);
    overflow-y: auto;

    .sidebar-menu-button{
        outline: none !important;
        border: none !important;
        line-height: 30px;
    }
}

.sidebar-heading{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.mdk-drawer-layout__content{
    min-height: calc(100vh - 64px) !important;
}

.sidebar-menu-button{
    box-shadow: none !important;
}