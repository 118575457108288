@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// for theme
@import "bootstrap/dist/css/bootstrap.min.css";
@import "perfect-scrollbar/css/perfect-scrollbar.css";
@import "material-design-icons/iconfont/material-icons.css";
@import "font-awesome/css/font-awesome.min.css";

@import "../assets/vendor/spinkit.css";
@import "../assets/css/app.css";

// customization
@import "./Shared/index";
@import "./Auth/index.scss";
@import "./global.scss";
