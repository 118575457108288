/* OrgChartComponent.scss */
.orgchart-container {
  border: none !important;
  height: 100% !important;
  .orgchart {
    background: none;
  }
  .oc-node {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px;
    margin: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 190px;

    .oc-title {
      background-color: #0b4c8d;
      color: #fff;
      font-size: 0.7rem;
      font-weight: bold;
      padding: 2px 5px;
      border-radius: 4px 4px 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .oc-content {
      padding: 10px;
      font-size: 0.9rem;
      color: #333;
      width: 100% !important;
      border: #007bff solid 1px !important;
      .code {
        color: #007bff;
      }

      .stats {
        color: black;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 10px 0;
        font-size: 0.75rem;
      }
    }

    img {
      border-radius: 50%;
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
    }
  }

  .oc-edge {
    border-color: #007bff;
  }
}

.orgchart ul li .oc-node:not(:only-child)::after {
  background-color: transparent !important;
}

.orgchart > ul > li > ul li::before {
  border-top: 2px solid #0b4c8dcc !important;
}

.orgchart > ul > li > ul li > .oc-node::before {
  background-color: #0b4c8dcc !important;
}

// disable drag and drop
/* OrgChartComponent.css */
.orgchart-container .orgchart {
  .node,
  .lines,
  .level {
    pointer-events: none;
  }
}

.orgchart-container .orgchart .node * {
  pointer-events: auto; /* Allow pointer events for node content */
}
.stepper-currentStep {
  font-size: 1.2em;
}
