.custom-valid-input {
    border-color: #66bb6a;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2366BB6A' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: calc(.75em + .5rem) calc(.75em + .5rem);
background-position: right calc(0.375em + 0.1875rem) center;
}

.custom-invalid-input {
    border-color: #f44336 !important;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23F44336'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23F44336' stroke='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: calc(.75em + .5rem) calc(.75em + .5rem);
    background-position: right calc(0.375em + 0.1875rem) center;
}

.valid-feedback,
.invalid-feedback{
    display: block !important;
}


.required-label{
    position: relative;
}
.required-label::after{
    content: '*';
    color: #f44336;
    font-size: 15px;
    position: absolute;
    top: -2px;
    right: -10px;
}

.react-international-phone-country-selector-dropdown{
    z-index: 9 !important;
}

/* Chrome, Safari, Edge, Opera */
.hidden-controls-number::-webkit-outer-spin-button,
.hidden-controls-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hidden-controls-number[type=number] {
  -moz-appearance: textfield;
}