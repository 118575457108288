.authopages-template-wrapper{
    height: 100vh;
    min-height: 500px;
    overflow: hidden;

    
    .form-side {
        background: #252526;
        width: 40%;
    }
    .img-side{
        width: 60%;

        img{
            object-fit: cover;
        }
    }
    @media (min-width:992px) and (max-width:1200px) {
        .form-side {
            width: 50%;
        }
        .img-side{
            width: 50%;
        }   
    }
    
    @media (max-width:992px) {
        .img-side{
            display: none;
        }
        .form-side{
            width: 100%;
        }
    }   
}

.auth-form-container{
    width: 80%;
    @media (min-width:768px) {
        width: 60%;
    }
}

.login-logo{
    width: 200px;
}

.auth-form-input{
    background: rgba(255, 255, 255, 0.1) !important;
    color: white !important;
    border: 1px solid #e5e5e5;
}
.auth-form-input[name='password']{
    padding-right: 36px;
}

.show-or-hide-password{
    position: absolute;
    top: 22px;
    right: 0px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    z-index: 9;

}
.auth-form-submit-btn{
   background-color: $white !important;
    width: 100%;
    color: $black !important;
    border: none !important;
}

