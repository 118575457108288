.request-details {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin: auto;

  h2 {
    margin: 0;
    font-size: 24px;
    color: #0b4c8d;
  }

  .request-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .accept-btn,
    .reject-btn {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      margin-left: 10px;

      &.accept-btn {
        background-color: #28a745;
        color: #fff;

        &:hover {
          background-color: #218838;
        }
      }

      &.reject-btn {
        background-color: #dc3545;
        color: #fff;

        &:hover {
          background-color: #c82333;
        }
      }
    }
  }
}

.request-body {
  display: flex;
  justify-content: space-between;

  .request-section {
    flex: 1;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }

    h3 {
      margin: 0;
      margin-bottom: 10px;
      font-size: 18px;
      color: #0b4c8d;
    }

    p {
      margin: 0;
      margin-bottom: 10px;
      font-size: 14px;
      color: #555;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
  }
}

.download-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;

  &:hover {
    background-color: #0056b3;
  }
}

.request-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 30px;

  p {
    margin: 0;
    font-size: 14px;
    color: #777;

    span {
      color: #0b4c8d;
      font-weight: bold;
    }
  }
}
.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ReactModal__Content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
}

.custom-modal {
  width: 100%;
  margin: 0 auto;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  outline: none;
  padding: 20px;
}
// Custom styles
.modal-content {
  padding: 20px;
  border-radius: 8px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  font-size: 1.5rem;
}

label {
  font-weight: bold;
  margin-top: 1rem;
}

textarea,
input {
  width: 100%;
  padding: 10px;
  margin-top: 0.5rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

textarea {
  height: 100px;
}

button {
  margin-top: 1rem;
  padding: 10px 20px;
  border-radius: 4px;
}

button:first-of-type {
  margin-right: 10px;
}
