// fonts 
$ff-ubuntu: 'Ubuntu',
    sans-serif;
$ff-sageo :'Segoe UI',
sans-serif;
$ff-opensans: 'Open Sans',
    sans-serif;
// ==================================
$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;

$fw-Thin :100;
$fw-Extra-Light :200;
$fw-Light :300;
$fw-Regular :400;
$fw-Medium :500;
$fw-Semi-Bold :600;
$fw-Bold :700;
$fw-Extra-Bold :800;
$fw-Black :900;

// =================================
// $main-color: #FECC00;
$white: #FFFFFF;
$black: #000;
$gray1: #DEDEDE;
$gray2: #B8B8B8;
$gray3: #F1F1F1;
$gray4: #7d7d7d;

$gray3-border: #C9C9C9;
$gray4-border: #d2d2d2;
$gray5-border: #B9B9B9;


$red1: #DB4E23;


// ============================================
.c-white{
    color: $white;
}

.form-control {
    outline: none !important;
box-shadow: none !important;
}