.AllMatchedPartners{
    width: 100%;
    position: absolute;
    top: 75px;
    left: 0;
    background: rgb(204 204 204 / 100%);
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 300px;
    z-index: 9;


    .item{
        padding: 10px 15px;
        font-size: 14px; 
        cursor: pointer;
    }
}